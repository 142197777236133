import { memo, useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
// eslint-disable-next-line react/display-name
const HeadCustom = memo(({ metatags, title, description }) => {
  const [parsedMetaTags, setParsedMetaTags] = useState([]);
  const { asPath } = useRouter();
  const url = "https://www.erseymakina.com" + asPath;

  useEffect(() => {
    if (metatags) {
      const parsedTags = parseMetaTags(metatags);
      setParsedMetaTags(parsedTags);
    }
  }, [metatags]);

  const parseMetaTags = (metatags) => {
    if (typeof window === "undefined") {
      return [];
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(`<div>${metatags}</div>`, "text/html");
    const metaElements = Array.from(doc.querySelectorAll("meta"));
    return metaElements.map((element, index) => {
      const props = {};
      for (let attr of element.attributes) {
        props[attr.name] = attr.value;
      }
      return <meta key={index} {...props} />;
    });
  };
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {url && <meta property="og:url" content={url} />}
      {url && <link rel="canonical" href={url} />}
      {parsedMetaTags}
    </Head>
  );
});

export default HeadCustom;
